/**
 * Implement Gatsby's Browser APIs in this file.
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import ReactGA from "react-ga4"
import { Location } from "@reach/router"
import { Auth0Provider } from "./src/hooks/useAuth0"

export const wrapRootElement = ({ element }) => {
  if (window && (process.env.GATSBY_SPARKLE_PATH === "https://sprkl.es/" || process.env.GATSBY_SPARKLE_PATH === "https://sparkl.es/")) {
    ReactGA.initialize("G-WML0DK1SFY")
  }
  return <Location>{() => <Auth0Provider children={element} />}</Location>
}

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location.pathname === "/") {
    // ROOT redirects to marketing site (different codebase - repo = SparkleMarketingSite)
    if (process.env.GATSBY_SPARKLE_PATH === "https://sprkl.es/") {
      window.location.replace("https://wesparkle.co/")
    } else if (
      process.env.GATSBY_SPARKLE_PATH === "https://sprkl-staging.herokuapp.com/"
    ) {
      // TODO: eventually setup and redirect to staging version of marketing site
      // FOR NOW, just use old marketing code at root of sparkle site code here
    } else if (
      process.env.GATSBY_SPARKLE_PATH === "https://sprkl-dev.herokuapp.com/"
    ) {
      // TODO: eventually setup and redirect to hosted dev version of marketing site
      // FOR NOW, just use old marketing code at root of sparkle site code here
    } else if (
      process.env.GATSBY_SPARKLE_PATH === "https://preview.sprkl.es/"
    ) {
      // TODO: eventually setup and redirect to hosted dev version of marketing site
      // FOR NOW, just use old marketing code at root of sparkle site code here
    } else if (
      process.env.GATSBY_SPARKLE_PATH === "https://devwesparkle.ngrok.io/"
    ) {
      window.location.replace("http://localhost:8001/")
    } else if (process.env.GATSBY_SPARKLE_PATH === "http://localhost:8000/") {
      // not sure if this is okay but i need it for local dev -OKKUR
    } else {
      window.location.replace("https://wesparkle.co/")
    }
  } else if (process.env.GATSBY_SPARKLE_PATH === "https://sprkl.es/") {
    // Send pageview with a custom path
    ReactGA.send({
      hitType: "pageview",
      page: `${location.pathname + location.search}`,
    })
  }
}
