import { useEffect, useRef } from "react"
import useIsBrowser from "./useIsBrowser"
import { getSelectedBooking } from "../sparklePage/utils"

const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
const isBrowser = () => {
  return typeof window !== "undefined" && window
}

const segmentAction = {
  track: (
    title = "",
    data = {}, // Getting tracked
    extra = {} // Not tracked but used to get additionalData if need be
  ) => {
    const browser = isBrowser()
    let additionalData = {}

    // Get additional data or products
    if (title.toLowerCase().includes("product") && extra.product) {
      const {
        id: productId,
        name: productName,
        price: productPrice,
        quantityAvailable,
        requiresShipping,
        storePickupEnabled,
      } = extra.product || {}
      additionalData = {
        productId,
        productName,
        productPrice,
        quantityAvailable,
        requiresShipping,
        storePickupEnabled,
      }
    }

    // Get additional data for bookings
    if (
      title.toLowerCase().includes("book") &&
      extra?.tenantInfo &&
      data?.bookingId
    ) {
      const {
        payment_type: bookingPaymentType,
        type: bookingType,
        name: bookingName,
        legnth: bookingLength,
        where: bookingWhere,
      } = getSelectedBooking({
        tenantInfo: extra.tenantInfo,
        bookingId: data.bookingId,
      }) || {}
      additionalData = {
        bookingPaymentType,
        bookingType,
        bookingName,
        bookingLength,
        bookingWhere,
      }
    }

    if (browser && window && window.analytics) {
      window.analytics.track(toTitleCase(title), {
        ...data,
        ...additionalData,
        ...window.segmentSparkleTracking,
      })
    }
  },
  identify: (id, user) => {
    const { name, email, sparkleTenantName, sparkleTenantUrl } = user || {}
    const browser = isBrowser()
    if (browser && window && window.analytics) {
      window.analytics.identify(id, {
        name,
        email,
        sparkleTenantName,
        sparkleTenantUrl,
      })
    }
  },
}

// ON LOAD / MOUNT USE EFFECT
const useSegmentTrackLoad = (
  title = "",
  tenantUrl, // only for sparkle sites not homepage
  save = {} // only for sparkle sites not homepage
) => {
  const isBrowser = useIsBrowser()
  const logged = useRef(false) // for first load
  useEffect(() => {
    if (
      isBrowser &&
      window &&
      window.analytics &&
      !(logged && logged.current)
    ) {
      logged.current = true
      window.analytics.track(
        `${toTitleCase(title)} Load`,
        tenantUrl ? { tenantUrl } : {}
      )
    }
  }, [isBrowser, title, tenantUrl])

  // Like super props for other events
  useEffect(() => {
    window.segmentSparkleTracking = save
  }, [isBrowser, save])
  return
}

export { useSegmentTrackLoad, segmentAction }
