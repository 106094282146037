/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect, useContext } from "react"
import { createAuth0Client } from "@auth0/auth0-spa-js"
import useIsBrowser from "./useIsBrowser"
import { navigate } from "@reach/router"
import { segmentAction } from "./useSegmentTrack"

const initOptions = {
  domain: process.env.GATSBY_AUTH0_DOMAIN,
  clientId: process.env.GATSBY_AUTH0_CLIENTID,
  authorizationParams: {
    redirect_uri: process.env.GATSBY_AUTH0_CALLBACK,
  },
  cacheLocation: "localstorage",
}

// const DEFAULT_REDIRECT_CALLBACK = () => {
//   if (window) {
//     window.history.replaceState({}, '', window.location.pathname)
//   }
// }

export const Auth0Context = createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({ children }) => {
  const isBrowser = useIsBrowser()
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const onRedirectCallback = (appState) => {
      const fallbackRedirectPath = isBrowser ? window?.location?.pathname : ""
      if (isBrowser) {
        if (appState && appState.targetUrl === "/" && isAuthenticated) {
          window.location.assign(process.env.GATSBY_SPARKLE_ADMIN_PATH)
        } else {
          const url =
            appState && appState.targetUrl
              ? appState.targetUrl
              : fallbackRedirectPath
          navigate(url)
        }
      }
    }

    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions)
      setAuth0(auth0FromHook)
      if (
        window &&
        window.location &&
        window.location.search &&
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }
      const isAuthenticated = await auth0FromHook.isAuthenticated()
      setIsAuthenticated(isAuthenticated)
      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        segmentAction.identify(
          user["https://wesparkle.biz/sparkleTenantId"] || user.sub,
          user
        )
        setUser(user)
      }
      setLoading(false)
    }
    if (isBrowser) {
      initAuth0()
    }
  }, [isBrowser])
  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    setUser(user)
    setIsAuthenticated(true)
  }
  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setIsAuthenticated(true)
    setUser(user)
    setLoading(false)
  }
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}
